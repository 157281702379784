import {
    Box,
    BoxProps,
    Flex,
    Heading,
    Image,
    Skeleton,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useState } from 'react';

type ImageTeaserProps = BoxProps & {
    image?: string | null;
    label: string;
    href: string;
};

const ImageTeaser: React.FC<ImageTeaserProps> = ({
    image,
    label,
    href,
    ...props
}) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return (
        <Link href={href}>
            <a>
                <Box
                    pos="relative"
                    _hover={{
                        shadow: 'lg',
                    }}
                    {...props}
                >
                    <Skeleton isLoaded={isImageLoaded} overflow="hidden">
                        <Image
                            onLoad={() => setIsImageLoaded(true)}
                            width="100%"
                            src={image!}
                            transition="all .2s ease"
                            _hover={{
                                transform: 'scale(1.05)',
                            }}
                        />
                    </Skeleton>

                    <Flex
                        pos="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        background="linear-gradient(to top, rgba(0,0,0, .8), transparent)"
                        padding={4}
                        paddingTop={12}
                        color="#fff"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Heading fontSize="xl">{label}</Heading>
                    </Flex>
                </Box>
            </a>
        </Link>
    );
};

export default ImageTeaser;
