import { Flex } from '@chakra-ui/react';
import { ParagraphActivityPoiTeaserListFragmentFragment } from '../../../generated/types';
import Container from '../../Layout/Container';
import ImageTeaser from '../../Common/ImageTeaser';
import TeaserGrid from '../../Common/TeaserGrid';
import Wrapper from '../Wrapper';
import React from 'react';
import ParagraphTitle from '../ParagraphTitle';

interface ActivityPoiTeaserListProps {
    data: ParagraphActivityPoiTeaserListFragmentFragment;
}

const ActivityPoiTeaserList: React.FC<ActivityPoiTeaserListProps> = ({
    data,
}) => {
    return (
        <Wrapper className="ParagraphActivityPoiTeaserList">
            <Container>
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={4}
                >
                    <ParagraphTitle label={data.title} sub={data.subtitle} />
                </Flex>

                <TeaserGrid>
                    {data.teaser!.map((teaser) => (
                        <ImageTeaser
                            href={`/entdecken/${
                                teaser.termReference!.frontendUrl
                            }`}
                            // href="/entdecken/[[...path]]"
                            key={teaser.id}
                            label={teaser.title || ''}
                            image={
                                teaser.termReference!.image &&
                                teaser.termReference!.image.teaser &&
                                teaser.termReference!.image.teaser.url
                            }
                        />
                    ))}
                </TeaserGrid>
            </Container>
        </Wrapper>
    );
};

export default ActivityPoiTeaserList;
